import logo from './logo.PNG';
import './App.css';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import BLENDMODE_privacy from './BLENDMODE_privacy';

const Stack = createNativeStackNavigator();

const config = {
  screens: {
    Home: '',
    Privacy: 'blendmodeprivacy',
  },
};

const linking = {
  prefixes: ['localhost:3000', 'nonsensematters.com'],
  config,
};

function Home() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          <code>nonsense._matters</code>
        </p>
        <p>
          info@nonsensematters.com
        </p>
      </header>
    </div>
  );
}

function App() {
  return (
    <NavigationContainer linking={linking}>
      <Stack.Navigator initialRouteName="Home">
        <Stack.Screen name="Home" component={Home} />
        <Stack.Screen name="Privacy" component={BLENDMODE_privacy} />
      </Stack.Navigator>
    </NavigationContainer>
  )
}

export default App;
